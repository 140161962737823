* {
  margin: 0;
  box-sizing: inherit;
  scroll-behavior: smooth;
  font-family: 'Times New Roman', Times, serif;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  background: linear-gradient(-45deg, #c9df8c, #8fe28f, #7086c5, #a66dc8);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

/* 
sections of each of the pages
*/
#welcome-section, #contact-section, #about-section, 
#projects-section, #experience-section, #project-landing, 
#project-process, #project-pages {
  height: 100vh;
  width: 100vw;
}

.contact-bar {
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  margin: 20px;
}

#welcome-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.introduction > h2 {
  font-size: 1rem;
}

.my-name-is {
  font-size: 2rem;
}

.intro {
  font-size: 1rem;
}

#about-section {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.bio {
  width: 35%;
  margin-left: 10%;
  margin-right: 5%;
  display: flex;
  flex-direction: column;
  left: 0;
}

.photo {
  width: 35%;
  margin-left: 5%;
  margin-right: 10%;
  right: 0;
}

.photo > img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.name {
  font-family:  Brush Script MT, Brush Script Std, cursive;
  font-weight: 500;
  font-size: x-large;
}

.name:hover{
  text-decoration: none;
}

nav {
  position: fixed;
  width: 100%;
  height: 48.5px;
  top: 0;
  left: 0;
  z-index: 3;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

#navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: rgba(213, 213, 213, 0.568); */
  border-bottom: 2px solid;
}

.nav-bar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  width: calc(100% - 34.49px);
}

.nav-links {
  width: 500px;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.dropdown {
  position: relative;
  
}

.dropdown-menu {
  display: none;
  /* -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.8); */
}

.dropdown-menu.show {
  width: 200px;
  display: flex;
  flex-direction: column;
  overflow: visible;
  position: absolute;
  padding-top: 35px;
  
}

.dropdown-item {
  margin-bottom: 10px;
}

@media only screen and (max-width: 800px) {
  .nav-bar > ul {
      width: 50%;
  }
}

.nav-bar > #initials {
  left: 0%;
  display: flex;
}

.underline:hover {
  text-decoration: underline;
}

a {
  color: black;
  background: var(--nav-color);
  font-weight: bold;
  text-decoration: none;
}

#projects-wrapper {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 65%;
  padding: 20px;
}

.section-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  justify-content: center;
  width: 100%;
  padding: 3px;
  align-items: center;
}

.small-text {
  font-size: 1rem;
}

#slider {
  width: 80%;
}

.project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, max(175px, 100%/2)), 1fr));
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

#projects-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-tile {
  position: relative;
  padding: 10px;
  /* box-shadow: 2px 2px 5px black; */
}

.slick-track {
  margin-top: auto;
  margin-bottom: auto;
}

.project-tile > a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  overflow: hidden;
}

.slick-slide:not(.slick-active) {
  opacity: 65%;
}

.slick-slide {
  margin-top: auto;
  margin-bottom: auto;
}

.project-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.centered {
  position: absolute;
  z-index: -1;
  border: 2px solid black;
  padding: 10px;
}

.project-img:hover {
  animation:  fade-out .3s; 
  opacity: .2;
}


.project-desc {
  padding: 2rem 0.5rem;
}

@keyframes fade-out {
  0% { opacity: 1; }
  100% { opacity: .2; }
}

@keyframes enlarge {
  0% { transform: scale(1); }
  100% { transform: scale(1.2); }
}

#project-landing {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}



.project-header {
  position: absolute;
  top: 10%;
}

.project-info {
  width: 80%;
  height: 70%;
  position: absolute;
  top: 20%;
  /* border: solid 1px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.project-visuals, .project-description {
  width: 45%;
  height: 80%;
  position: absolute;
  /* border: dashed 1px; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 800px) {
  .project-info {
    display: flex;
    flex-direction: column;
  }

  .project-visuals, .project-description {
    width: 80%;
    height: 50%;
    position: relative;
  }
}

.description-list > li {
  display: list-item;
  list-style: circle;
  list-style-type: circle;
}

.project-visuals {
  /* left: 0; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.picture {
  width: 100%;
}

.project-description {
  right: 0;
}

.tech-description, .word-description, 
.project-process {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* position: absolute; */
}

.word-description {
  height: 50%;
  /* border: dotted 1px blue; */
}

.tech-description {
  height: 20%;
  justify-content: flex-start;
  /* border: dotted 1px red; */
}

.word-description h2, .tech-description h2 {
  margin-bottom: 10px;
}

#project-process, #project-pages {
  display: flex;
  align-items: center;
  justify-content: center;
}

#project-pages {
 flex-direction: row;
}


.project-process {
  height: 10%;
  bottom: 0;
}

#experience-section {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.experience-animation {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 30%;
}

.column > ul {
  padding-left: 0;
}

.column > h1 {
  margin-bottom: 10px;
}

li {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

#contact-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
}

.contacts-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contact-grid {
  display: grid;
}

.contact {
  padding: 10px;
}

.fab {
  font-family: "Font Awesome 5 Brands";
  padding-right: 5px;
}

.fa-twitter:before {
  content: "\f099";
}

.fa-github:before {
  content: "\f09b";
}

.fa-linkedin:before {
  content: "\f0e1";
}



